import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { ILinkColumnChoiceEntity } from '../../interfaces/api/link-column-choice-entity';
import { paginationAdapter } from './link-column-choice.adapter';

export const LinkColumnChoicePaginationMessages = paginationAdapter.getMessages();

export class UpsertLinkColumnChoicePageMessage extends LinkColumnChoicePaginationMessages.UpsertPageMessage {}
export class UpsertLinkColumnChoiceMultiplePagesMessage extends LinkColumnChoicePaginationMessages.UpsertMultiplePagesMessage {}
export class ResetLinkColumnChoicePaginationMessage extends LinkColumnChoicePaginationMessages.ResetPaginationMessage {}

export enum LinkColumnChoiceSignalTypes {
  LOAD_LINK_COLUMN_CHOICES = '[LinkColumnChoice][Signal] Load Collection',
}
export enum LinkColumnChoiceMessageTypes {
  SET_COLLECTION = '[LinkColumnChoice][Message] Set Collection',
  ADD_ONE = '[LinkColumnChoice][Message] Add One',
  UPDATE_ONE = '[LinkColumnChoice][Message] Update One',
  UPSERT_ONE = '[LinkColumnChoice][Message] Upsert One',
  DELETE_ONE = '[LinkColumnChoice][Message] Delete One',
  ADD_MULTIPLE = '[LinkColumnChoice][Message] Add All',
  DELETE_MULTIPLE = '[LinkColumnChoice][Message] Delete Many',
  UPSERT_MULTIPLE = '[LinkColumnChoice][Message] Upsert Many',
  UPDATE_MULTIPLE = '[LinkColumnChoice][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = LinkColumnChoiceMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: ILinkColumnChoiceEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = LinkColumnChoiceMessageTypes.ADD_ONE;

  constructor(public payload: { entity: ILinkColumnChoiceEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = LinkColumnChoiceMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: ILinkColumnChoiceEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = LinkColumnChoiceMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: ILinkColumnChoiceEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = LinkColumnChoiceMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: ILinkColumnChoiceEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = LinkColumnChoiceMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<ILinkColumnChoiceEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = LinkColumnChoiceMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<ILinkColumnChoiceEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = LinkColumnChoiceMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = LinkColumnChoiceMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type LinkColumnChoiceMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
