import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src/';
import { createLoadingStateAdapter } from '@locumsnest/core/src/lib/adapters/loading-state-adapter';
import { ILinkColumnChoiceEntity } from '../../interfaces/api/link-column-choice-entity';

export function sortByName(a: ILinkColumnChoiceEntity, b: ILinkColumnChoiceEntity): number {
  return a.id - b.id;
}

export const adapter: EntityAdapter<ILinkColumnChoiceEntity> =
  createEntityAdapter<ILinkColumnChoiceEntity>({
    sortComparer: sortByName,
  });

export const messageableFactory =
  MessageableFactory.forFeature<'LinkColumnChoice'>('LinkColumnChoice');
export const signalableFactory =
  SignalableFactory.forFeature<'LinkColumnChoice'>('LinkColumnChoice');
export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export const loadingAdapter = createLoadingStateAdapter<'LinkColumnChoice'>(messageableFactory);

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedLinkColumnChoiceId: null,
});
